import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getUserInSession, setSessionContext } from "./actions/AppActions";
import { withRouter } from "react-router-dom";
import LoaderImage from "../app/common/LoaderImage";
import Loader from "react-loader-advanced";
import * as common from "./common/index";
import * as message from "./common/messages";
import * as data from "./common/menus";
import Overlay from "./navigation/components/globalnavigation/Overlay";
import {
  setMenuStateFromSession,
  setMenuParentName,
  getTopLevelMenus,
  setDisplaySideMenuBar,
  getAllMenus,
  selectTopLevelMenu
} from "../app/navigation/actions/GlobalNavigationActions";
import { setLoader } from "../app/actions/LoaderActions";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faPencilAlt,
  faInfoCircle,
  faCheck,
  faEye,
  faEyeSlash,
  faDownload,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faAngleDown,
  faSort,
  faLongArrowAltLeft,
  faLongArrowAltRight,
  faBan,
  faCaretLeft,
  faCaretRight,
  faDollarSign,
  faChevronDown,
  faChevronUp,
  faMobileAlt,
  faCog,
  faGlobe,
  faPhone,
  faHistory,
  faPlus,
  faChevronLeft,
  faChevronRight,
  faLaptop,
  faListUl,
  faChevronCircleRight,
  faTimes,
  faFilter,
  faSearchPlus,
  faSortUp,
  faSortDown,
  faCheckCircle,
  faEllipsisH,
  faCaretDown,
  faCaretUp,
  faPaperPlane,
  faUpload,
  faSearch,
  faHome,
  faCalculator,
  faCheckSquare,
  faBars,
  faChartLine,
  faExclamationTriangle,
  faUserCircle
} from "@fortawesome/free-solid-svg-icons";
import {
  faThumbsUp,
  faTimesCircle,
  faEnvelope,
  faFileAlt,
  faBell,
  faCopy,
  faCalendarAlt,
  faTrashAlt,
  faListAlt,
  faMinusSquare,
  faPlusSquare,
  faSquare,
  faCircle,
  faIdBadge,
  faEdit
} from "@fortawesome/free-regular-svg-icons";
import {
  loaderStyle,
  clearSessionFromLocalStorage,
  clearSessionFromSessionStorage,
  clearAxiosHeader,
  redirectToDashboard,
  setupWalkMe,
  startSignalR
} from "./constants";
import { logout } from "./actions";

library.add(
  faPencilAlt,
  faSquare,
  faUserCircle,
  faEdit,
  faPaperPlane,
  faCheck,
  faExclamationTriangle,
  faCircle,
  faListAlt,
  faHome,
  faCalculator,
  faUpload,
  faSearch,
  faFilter,
  faTrashAlt,
  faCaretDown,
  faCaretUp,
  faEye,
  faEyeSlash,
  faSearchPlus,
  faChevronLeft,
  faHistory,
  faListUl,
  faPlus,
  faSortUp,
  faSortDown,
  faCheckCircle,
  faEllipsisH,
  faCalendarAlt,
  faChevronCircleRight,
  faTimes,
  faBars,
  faMobileAlt,
  faEnvelope,
  faChevronRight,
  faBell,
  faPhone,
  faCog,
  faLaptop,
  faCopy,
  faGlobe,
  faInfoCircle,
  faChevronDown,
  faChevronUp,
  faCheck,
  faMinusSquare,
  faPlusSquare,
  faDownload,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faFileAlt,
  faIdBadge,
  faChartLine,
  faAngleDown,
  faLongArrowAltLeft,
  faLongArrowAltRight,
  faSort,
  faBan,
  faCaretLeft,
  faCaretRight,
  faDollarSign,
  faTimesCircle,
  faThumbsUp,
  faCheckSquare
);
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inSession: false,
      height: window.innerHeight,
      isSubscribed: false,
      showAppPage: true
    };
    const token = sessionStorage.getItem("Authorization");
    const SessionContext = sessionStorage.getItem("SessionContext");
    axios.defaults.headers.common["Authorization"] = token;
    axios.defaults.headers.common["SessionContext"] = SessionContext;
    this.props.setSessionContext();
    this.props.getUserInSession();
    this.checkLocalStorage();
  }

  checkLocalStorage = () => {
    let localSessionContext = localStorage.getItem("SessionContext");
    let localAuthorization = localStorage.getItem("Authorization");
    if (localSessionContext !== null && localAuthorization !== null) {
      axios.defaults.headers.common["Authorization"] = localAuthorization;
      sessionStorage.setItem("Authorization", localAuthorization);
      axios.defaults.headers.common["SessionContext"] = localSessionContext;
      sessionStorage.setItem("SessionContext", localSessionContext);
      this.props.setSessionContext();
    }

    if (window.location.href.indexOf("/view-job-history/") > -1) {
      this.props.selectTopLevelMenu(
        true,
        0,
        { SelectedProductId: -1 },
        { SelectedProductId: -1 },
        true
      );
      this.props.setMenuParentName("Notifications");
      sessionStorage.setItem("MenuParentName", "Notifications");
    }
  };

  componentDidMount() {
    setupWalkMe();
  }

  getPageAccess = () => {
    this.props.setLoader(true);
    let pageMenuItem = [];
    let accessMenuUrl = window.location.pathname;
    if (window.location.pathname !== "/" && data.menus.length > 0) {
      pageMenuItem = data.menus.filter(item =>
        accessMenuUrl.includes(item.menuUrl)
      );

      if (pageMenuItem.length > 0) {
        accessMenuUrl = pageMenuItem[0].parentUrl;
      }
      this.props.getAllMenus(menuList => {
        let menuItem = [];
        if (menuList.Data.length > 0) {
          menuItem = menuList.Data.filter(
            item => "/" + item.Url === accessMenuUrl
          );
        }
        if (
          window.location.pathname !== "/" &&
          window.location.pathname !== "/authenticate" &&
          menuItem.length === 0
        ) {
          this.setState({ showAppPage: false });
          const url = `${common.WEB_API_URL}/api/app/signout`;
          clearSessionFromLocalStorage();
          clearSessionFromSessionStorage();

          axios
            .post(url)
            .then(response => {
              clearAxiosHeader();
              localStorage.removeItem("LoggedInLegacy");
              /** Clears Session context from reducer */
              this.props.setSessionContext();

              window.location.href =
                common.LEGACY_LOGIN_URL + "/n/Account/Logout";
            })
            .catch(error => {
              clearAxiosHeader();
              /** Clears Session context from reducer */
              this.props.setSessionContext();
              localStorage.removeItem("LoggedInLegacy");

              window.location.href =
                common.LEGACY_LOGIN_URL + "/n/Account/Logout";
            });
        }
        this.props.setLoader(false);
      });
    }
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.checkUserInSession !== null) {
      if (this.props.checkUserInSession !== nextProps.checkUserInSession) {
        this.setState({
          inSession: nextProps.checkUserInSession
        });
        if (!nextProps.checkUserInSession) {
          clearAxiosHeader();
          clearSessionFromLocalStorage();
          clearSessionFromSessionStorage();
          /** Clears Session context from reducer */
          this.props.setSessionContext();
          redirectToDashboard();
        }
      }
    }

    if (
      this.props.checkUserInSession === null &&
      nextProps.checkUserInSession === true
    ) {
      this.setState({
        inSession: nextProps.checkUserInSession
      });

      if (
        nextProps.checkUserInSession === true &&
        sessionStorage.getItem("SessionContext") !== null
      ) {
        let MenuStateData = JSON.parse(sessionStorage.getItem("MenuState"));
        if (window.location.pathname === "/") {
          clearAxiosHeader();
          clearSessionFromLocalStorage();
          clearSessionFromSessionStorage();
          /** Clears Session context from reducer */
          this.props.setSessionContext();
        } else {
          this.props.setMenuStateFromSession();
          this.props.setMenuParentName(
            sessionStorage.getItem("MenuParentName")
          );
          if (
            sessionStorage.getItem("allMenusList") !== undefined &&
            sessionStorage.getItem("allMenusList") !== null
          ) {
            this.props.getTopLevelMenus(
              MenuStateData[1].SelectedProductId,
              JSON.parse(sessionStorage.getItem("allMenusList"))
            );
            if (
              typeof MenuStateData[1].SelectedMenuLevel3Id !== "undefined" &&
              MenuStateData[1].SelectedMenuLevel3Id !== 0
            ) {
              this.props.setDisplaySideMenuBar(true);
            }
          }
          this.props.history.push(window.location.pathname);
        }
      }
    } else if (
      this.props.checkUserInSession === null &&
      nextProps.checkUserInSession === false &&
      sessionStorage.getItem("SessionContext") === null
    ) {
      if (process.env.REACT_APP_ENABLE_USERTESTING === "true") {
        this.props.history.push(window.location.pathname);
      } else if (
        window.location.pathname === "/devlogin" &&
        (process.env.NODE_ENV === "development" ||
          window.location.origin ===
          "https://dev-rewrite-ui.azurewebsites.net" ||
          window.location.origin ===
          "https://staging-rewrite-ui.azurewebsites.net")
      ) {
        this.props.history.push(window.location.pathname);
      } else {
        this.props.history.push("/");
      }
    }
  }

  componentWillUpdate() {
    this.props.getUserInSession();
  }

  componentDidUpdate() {
    if (!this.state.isSubscribed && this.state.inSession) {
      startSignalR(this.props.sessionContext.UserId);
      this.setState({
        isSubscribed: true
      });

      if (this.props.sessionContext) {
        this.getPageAccess();
      }
    }
  }

  render() {
    const spinner = <LoaderImage />;
    const {
      netchexLoader = {},
      children,
      isDisplayMainPageOverlayPanel,
      isDataFetching
    } = this.props;
    const loaderState =
      netchexLoader.state === null ? false : netchexLoader.state;
    return (
      <Loader
        show={loaderState || isDataFetching}
        message={spinner}
        contentBlur={2}
        backgroundStyle={loaderStyle}
      >
        {this.state.showAppPage && (
          <div className="data-react-root" id="data-react-root">
            <Overlay show={isDisplayMainPageOverlayPanel}>
              <div className="app-main-page" id="app-main-page">
                <div className="app-work-page">{children}</div>
              </div>
            </Overlay>
          </div>
        )}
      </Loader>
    );
  }
}

App.propTypes = {
  children: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    checkUserInSession: state.checkUserInSession,
    isDisplayMainPageOverlayPanel:
      state.menuReducer.isDisplayMainPageOverlayPanel,
    sessionContext: state.sessionContext,
    netchexLoader: state.netchexLoader,
    isInAuthProcess: state.isInAuthProcess,
    isDataFetching: state.dataFetchLoader
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getUserInSession: getUserInSession,
      setSessionContext: setSessionContext,
      setMenuStateFromSession: setMenuStateFromSession,
      setMenuParentName: setMenuParentName,
      getTopLevelMenus: getTopLevelMenus,
      setDisplaySideMenuBar: setDisplaySideMenuBar,
      setLoader: setLoader,
      getAllMenus,
      selectTopLevelMenu
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
);
