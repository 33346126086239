export const COMPANY_CALCULATION_FETCH_CALCULATION_METHOD_OPTIONS_LIST =
  "COMPANY_CALCULATION_FETCH_CALCULATION_METHOD_OPTIONS_LIST";

export const COMPANY_FETCH_CATEGORY_OPTIONS_LIST =
  "COMPANY_FETCH_CATEGORY_OPTIONS_LIST";

export const COMPANY_FETCH_DEDUCTION_VALUES_LIST =
  "COMPANY_FETCH_DEDUCTION_VALUES_LIST";

export const COMPANY_CALCULATION_FETCH_EARNING_PERCENT_OPTIONS_LIST =
  "COMPANY_CALCULATION_FETCH_EARNING_PERCENT_OPTIONS_LIST";

export const FETCH_COMPANY_ACA_DEDUCTION_OPTION_LIST =
  "FETCH_COMPANY_ACA_DEDUCTION_OPTION_LIST";

export const FETCH_COMPANY_ACA_DEDUCTION_OPTION_DROPDOWN_LIST =
  "FETCH_COMPANY_ACA_DEDUCTION_OPTION_DROPDOWN_LIST";

export const COMPANY_FETCH_TAXABILITY_VALUES_LIST =
  "COMPANY_FETCH_TAXABILITY_VALUES_LIST";

export const COMPANY_CALCULATION_FETCH_CUSTOM_EARNINGS_OPTIONS_LIST =
  "COMPANY_CALCULATION_FETCH_CUSTOM_EARNINGS_OPTIONS_LIST";

export const COMPANY_FETCH_W2BOX14_VALUES_LIST =
  "COMPANY_FETCH_W2BOX14_VALUES_LIST";

export const FETCH_EXCLUDE_STATES_VALUES_LIST =
  "FETCH_EXCLUDE_STATES_VALUES_LIST";

export const FETCH_REPORTED_TAX_TYPE_LIST = "FETCH_REPORTED_TAX_TYPE_LIST";

export const FETCH_REPORTED_WAGES_LIST = "FETCH_REPORTED_WAGES_LIST";

export const COMPANY_CALCULATION_FETCH_ADVANCED_PRETAX_OPTIONS_LIST =
  "COMPANY_CALCULATION_FETCH_ADVANCED_PRETAX_OPTIONS_LIST";

export const COMPANY_FETCH_CONTRIBUTION_VALUES_LIST =
  "COMPANY_FETCH_CONTRIBUTION_VALUES_LIST";

export const COMPANY_CALCULATION_FETCH_ADVANCED_RETIREMENT_OPTIONS_LIST =
  "COMPANY_CALCULATION_FETCH_ADVANCED_RETIREMENT_OPTIONS_LIST";

export const COMPANY_CALCULATION_FETCH_GARNISHMENT_EARNINGS_OPTIONS_LIST =
  "COMPANY_CALCULATION_FETCH_GARNISHMENT_EARNINGS_OPTIONS_LIST";

export const ADD_DEDUCTION_TAB_MAIN_LIST = "ADD_DEDUCTION_TAB_MAIN_LIST";

export const ADD_DEDUCTION_TAB_SUB_LIST = "ADD_DEDUCTION_TAB_SUB_LIST";

export const ADD_DEDUCTION_TAB_SUB_RADIO_BUTTON_LIST =
  "ADD_DEDUCTION_TAB_SUB_RADIO_BUTTON_LIST";

export const ADD_DEDUCTION_FETCH_MEDICAL_TYPE_LIST =
  "ADD_DEDUCTION_FETCH_MEDICAL_TYPE_LIST";

export const ADD_DEDUCTION_FETCH_DENTAL_TYPE_LIST =
  "ADD_DEDUCTION_FETCH_DENTAL_TYPE_LIST";

export const ADD_DEDUCTION_FETCH_VISION_TYPE_LIST =
  "ADD_DEDUCTION_FETCH_VISION_TYPE_LIST";

export const ADD_DEDUCTION_FETCH_SUPPLEMENTAL_TYPE_LIST =
  "ADD_DEDUCTION_FETCH_SUPPLEMENTAL_TYPE_LIST";

export const ADD_DEDUCTION_FETCH_CUSTOM_TYPE_LIST =
  "ADD_DEDUCTION_FETCH_CUSTOM_TYPE_LIST";

export const COMPANY_LISTING_FETCH_CATEGORY_LIST =
  "COMPANY_LISTING_FETCH_CATEGORY_LIST";

export const COMPANY_LISTING_FETCH_STATUS_LIST =
  "COMPANY_LISTING_FETCH_STATUS_LIST";

export const COMPANY_LISTING_FETCH_DEDUCTION_LIST =
  "COMPANY_LISTING_FETCH_DEDUCTION_LIST";

//
export const COMPANY_DEDUCTION_FILTERED_LIST =
  "COMPANY_DEDUCTION_FILTERED_LIST";

export const COMPANY_DEDUCTION_MAIN_LIST = "COMPANY_DEDUCTION_MAIN_LIST";

export const RAIN_DEDUCTION_MAIN_LIST = 
  "RAIN_DEDUCTION_MAIN_LIST";

//Filters
export const SYSTEM_DEDUCTION_SELECTED_FILTERS =
  "SYSTEM_DEDUCTION_SELECTED_FILTERS";

//

export const COMPANY_LISTING_FETCH_DEDUCTION_DELETE_ICON_CLICK_LIST =
  "COMPANY_LISTING_FETCH_DEDUCTION_DELETE_ICON_CLICK_LIST";

export const COMPANY_OPTION_EMPLOYEE_VENDOR_CATEGORY_AMT_LIST =
  "COMPANY_OPTION_EMPLOYEE_VENDOR_CATEGORY_AMT_LIST";

export const COMPANY_OPTION_EMPLOYER_VENDOR_CATEGORY_AMT_LIST =
  "COMPANY_OPTION_EMPLOYER_VENDOR_CATEGORY_AMT_LIST";

export const COMPANY_OPTION_ENTERPRISE_PAYEE_LIST =
  "COMPANY_OPTION_ENTERPRISE_PAYEE_LIST";

export const COMPANY_OPTION_ASSIGN_EMPLOYER_DEDUCTION_EARNING_LIST =
  "COMPANY_OPTION_ASSIGN_EMPLOYER_DEDUCTION_EARNING_LIST";

export const COMPANY_OPTION_EMPLOYEE_EMPLOYER__REDUCTION_LIST =
  "COMPANY_OPTION_EMPLOYEE_EMPLOYER__REDUCTION_LIST";

export const COMPANY_DEFAULT_VALUE_EMPLOYER_CONTRIBUTION_LIST =
  "COMPANY_DEFAULT_VALUE_EMPLOYER_CONTRIBUTION_LIST";

export const COMPANY_DEFAULT_VALUE_ADD_NEW_THIRD_PARTY_PAYMENT_LIST =
  "COMPANY_DEFAULT_VALUE_ADD_NEW_THIRD_PARTY_PAYMENT_LIST";

export const FETCH_PAYROLL_DEDUCTION_LIST = "FETCH_PAYROLL_DEDUCTION_LIST";

export const FETCH_OTHER_WITHELD_DEDUCTION_LIST =
  "FETCH_OTHER_WITHELD_DEDUCTION_LIST";

export const FETCH_SCHEDULED_PAYROLL_WITHELD_DEDUCTION_LIST =
  "FETCH_SCHEDULED_PAYROLL_WITHELD_DEDUCTION_LIST";

export const COMPANY_LEVEL_DEDUCTION_DATA = "COMPANY_LEVEL_DEDUCTION_DATA";

export const COMPANY_DEDUCTION_SAVED_PAGES = "COMPANY_DEDUCTION_SAVED_PAGES";

export const DEDUCTION_TAX_PRIORITY_LIST = "DEDUCTION_TAX_PRIORITY_LIST";

export const CLEAR_DEDUCTION_TAX_PRIORITY_LIST =
  "CLEAR_DEDUCTION_TAX_PRIORITY_LIST";

// CompanyThirdPartyPayee

export const COMPANY_THIRD_PARTY_PAYEE_MAIN_LIST =
  "COMPANY_THIRD_PARTY_PAYEE_MAIN_LIST";

export const COMPANY_THIRD_PARTY_PAYEE_FILTERED_LIST =
  "COMPANY_THIRD_PARTY_PAYEE_FILTERED_LIST";

export const COMPANY_THIRD_PARTY_PAYEE_DATA = "COMPANY_THIRD_PARTY_PAYEE_DATA";

export const FETCH_ACTIVE_TPP_CATEGORY_LIST = "FETCH_ACTIVE_TPP_CATEGORY_LIST";

export const FETCH_ACTIVE_TPP_BY_CATEGORYID_STATEID =
  "FETCH_ACTIVE_TPP_BY_CATEGORYID_STATEID";

export const FETCH_ACTIVE_TPP_BY_CATEGORYID = "FETCH_ACTIVE_TPP_BY_CATEGORYID";

export const FETCH_STATES_LIST_OF_ACTIVE_TPP_BY_CATEGORYID =
  "FETCH_STATES_LIST_OF_ACTIVE_TPP_BY_CATEGORYID";

export const FETCH_ACTIVE_SYSTEM_TPP_LIST = "FETCH_ACTIVE_SYSTEM_TPP_LIST";

export const BUTTON_TYPE_ADD_OR_EDIT = "BUTTON_TYPE_ADD_OR_EDIT";

export const GET_REVIEW_SAVE_SNAPSHOT = "GET_REVIEW_SAVE_SNAPSHOT";

export const GET_TPP_REVIEW_SAVE_SNAPSHOT = "GET_TPP_REVIEW_SAVE_SNAPSHOT";

export const GET_MAP_IT_LINK_NAVIGATION = "GET_MAP_IT_LINK_NAVIGATION";
