import * as types from "../../actions/companylevel/index";

export default function(state = [], action) {
  switch (action.type) {
    case types.RAIN_DEDUCTION_MAIN_LIST: {
      return action.payload.data;
    }

    default:
      return state;
  }
}
